import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function LineChart({ chartData }) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return <Line data={chartData} options={options} />;
}

export default LineChart;