import { useState,useEffect,useContext } from "react";
import StepFive from "../../components/Immatriculation/StepFive";
import StepFour from "../../components/Immatriculation/StepFour";
import StepOne from "../../components/Immatriculation/StepOne";
import StepThree from "../../components/Immatriculation/StepThree";
import StepTwo from "../../components/Immatriculation/StepTwo";
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import { Helmet } from 'react-helmet-async';
import { getPaiementStatus, getReservationList } from "../../services/immatriculation.service";
import { getConnexion } from "../../services/auth.service";
import { isEmpty } from "../../utils/helper/functions";
const Immatriculation = () => {
  const [step, setStep] = useState(1);
  const [stepChk, setStepChk] = useState({step1:false,step2:false,step3:false,step4:false,step5:false});
  const { genres, modeles, marques, typeVehicules, ministeres,directions } = useContext(ComboContext);
  //const [models,setModels] = useState();
  const [items,setItems] = useState({});
  const [isLoading,setIsLoading] = useState(false);
  const [paiement,setPaiement] = useState();
  const [erreurs,setErreurs] = useState('');
  const linkPaiement = process.env.REACT_APP_SIPIM_PAIE_LINK;
  const [reservationListe,setReservationListe] = useState([]);
  const [immatriculation,setImmatriculation] = useState(
    {modeImmatriculation:'',
    reservation_id:'',
    idpays: 0,
    marque: 0,
    model: '0',
    carrosserie: 0,
    genre: '0',
    type: '0',
    annee:'',
    energie:'',
    numChassie:'',
    nbPorte:0,
    proprietaire: 0,
    acquisition:'achat',
    transmission:'Manuelle',
    nbPlaceAssise:0,
    nbPlaceDebout:0,
    dateP:'',
    carosserie:'',
    ancienNumMat:'',
    cylindre:0,
    kilometrage:0,
    couleur:'',
    dateImmat:'',
    ministere: 0,
    direction:'',
    autreministere:'',
    nbreEssuie:0,
    autredirection:'',
    typeOrganisme:"",
    paiementReference:'',
    isReferenceValid:false,
    categories:{
     AllGenres:[],
     AllTypes:[],
    },
    brands:{
      allMarques:[],
      allModeles:[]
    },
    organisations:{
     allMinisteres:[],
     allDirections:[],
    },
    ptc:0,
    pv:0,
    cu:0,
    pa:0,
    image1:'',
    image2:'',
    image3:'',
    image4:'',
  }); 

   async function ComboElements(){
    setIsLoading(true);
     const {status,ReserationList} = await getReservationList();
     if(status === 200 ) setReservationListe(ReserationList);

    setImmatriculation({...immatriculation,brands:{
      allMarques:marques,
      allModeles:modeles,
    },categories:{
      AllGenres:genres,
      AllTypes:typeVehicules
    },organisations:{
      allMinisteres:ministeres,
      allDirections:directions
    }});
    setIsLoading(false);
   }
   const getPaiement = async (reference) => {
    setErreurs('');setIsLoading(true);setPaiement('');setStepChk({...stepChk,step1:false});
    //  const resp =  await getConnexion().get(`https://sipimguinee.com/api/paiement?token=22ptywFN6yacOPsUl&reference=${reference}`);
    // const resp =  await getConnexion().get(`http://sipim.dvlpdsd.com/api/paiement?token=22ptywFN6yacOPsUl&reference=${reference}`);
      const resp =  await getConnexion().get(`${linkPaiement}?token=22ptywFN6yacOPsUl&reference=${reference}`);
      //console.log(resp.data.paiement.typeOrganisme);

      setPaiement(resp.data.paiement);
      
      if(resp.data.status === 200 && resp.data?.paiement?.status === 'Validé'){
        const getCategorie= immatriculation?.categories.AllGenres?immatriculation.categories.AllGenres.filter((c) => c.nom.toUpperCase().trim() === resp.data.paiement.genre.toUpperCase().trim()):[];
        let genreID = 0;
        if(getCategorie.length > 0)
           genreID = getCategorie[0]?.genre_id;
 
        setImmatriculation({...immatriculation,isReferenceValid:true,
          numChassie:resp.data.paiement.chassis,genre:genreID,pa:resp.data?.paiement.pf,
          cu:resp.data?.paiement.cu,pv:resp.data?.paiement.pv,nbPlaceAssise:resp.data?.paiement.nbrePlace,
           modeImmatriculation:resp.data?.paiement?.type_plaque,typeOrganisme:resp.data.paiement.typeOrganisme});
        setStepChk({...stepChk,step1:true}); setStep(step + 1);
       // console.log(resp.data.paiement)

      }
      else {
        if(resp.data?.paiement?.status === 'Non Validé')
          setErreurs("Paiement Non Validé."); 
        else setErreurs(resp.data?.messages); 

          setImmatriculation({...immatriculation,isReferenceValid:false});
          setStepChk({...stepChk,step1:false,step2:false,step3:false,step4:false,step5:false});
        }  
    setIsLoading(false);
   }
  useEffect(() => {
    ComboElements();
  },[genres]);

  const handleNextStep = () => {
  
    if(step === 1){
      // if(immatriculation.typeOrganisme === 'Privé' || immatriculation.typeOrganisme === 'Publique')
        getPaiement(immatriculation.paiementReference);
    
    }
    else{
       setStep(step + 1);
       if(erreurs !== '') setStep(1);
     }
    
    // else  //si c'est pas une immatriculation
    //   setStep(step + 1);
    
  }

  const handlePrevStep = () => {
   if(step === 1 )
     setStepChk({...stepChk,step1:false});
    setStep(step - 1);
  }

  return (
    <div className="immatriculation-page page">
      <Helmet>
        <title>Nouvelle immatriculation</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Nouvelle immatriculation</h2>
      <div className="stepper">
        <div className={`step ${step === 1 && "step-active"}`} onClick={ () =>  setStep(1) }>
          <div className="step-number">
            <span>1</span>
          </div>
          <div className="step-label">
            <p>Mode d'immatriculation</p>
          </div>
        </div>
        <div className={`step ${step === 2 && "step-active"}`} onClick={ () => stepChk.step1 && setStep(2) }>
          <div className="step-number">
            <span>2</span>
          </div>
          <div className="step-label">
            <p>Infos du vehicule</p>
          </div>
        </div>
        <div className={`step ${step === 3 && "step-active"}`} onClick={() => stepChk.step2  && setStep(3) }>
          <div className="step-number">
            <span>3</span>
          </div>
          <div className="step-label">
            <p>Infos du proprietaire</p>
          </div>
        </div>
        <div className={`step ${step === 4 && "step-active"}`} onClick={ () => stepChk.step3 && setStep(4) }>
          <div className="step-number">
            <span>4</span>
          </div>
          <div className="step-label">
            <p>Joindre des fichiers</p>
          </div>
        </div>
        <div className={`step ${step === 5 && "step-active"}`} onClick={ () => stepChk.step4 &&setStep(5) }>
          <div className="step-number">
            <span>5</span>
          </div>
          <div className="step-label">
            <p>Soumission</p>
          </div>
        </div>
      </div>
      <div className="step-components">
            {step === 1 && <StepOne handleNextStep={handleNextStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation}
            erreurs={erreurs} setErreurs={setErreurs} stepChk={stepChk}
            reservationListe={reservationListe}
            />}
            {step === 2 && <StepTwo handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} 
            immatriculation={immatriculation}  setImmatriculation = {setImmatriculation} items={items} 
            stepChk={stepChk} setStepChk={setStepChk} erreurs={erreurs} setStep={setStep} paiement ={paiement}/>}
            {step === 3 && <StepThree handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation} stepChk={stepChk} setStepChk={setStepChk} />}
            {step === 4 && <StepFour handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} immatriculation={immatriculation} setImmatriculation = {setImmatriculation} stepChk={stepChk} setStepChk={setStepChk} />}
            {step === 5 && <StepFive handlePrevStep={handlePrevStep} setImmatriculation = {setImmatriculation} immatriculation={immatriculation} stepChk={stepChk} setStepChk={setStepChk} setStep ={setStep} />}
       
      </div>
    </div>
  );
};

export default Immatriculation;
