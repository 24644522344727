import {useEffect,useState,useMemo} from 'react';
import { BiUpload } from 'react-icons/bi';
import { AiOutlineFile } from 'react-icons/ai';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
const StepFour = ({ handleNextStep, handlePrevStep,immatriculation,setImmatriculation,stepChk,setStepChk }) => {
  const [previews,setPreviews] = useState({image1:'',image2:'',image3:'',image4:''});
  const isstepValid = useMemo(() => {
    return immatriculation.image1 != '' && immatriculation.image2 != ''
           && immatriculation.image3 != '' && immatriculation.image4 != ''
  },[immatriculation]);
  const url = process.env.REACT_APP_URL + '/storage/';
  useEffect(() => {
     setPreviews({...previews,
      image1: typeof immatriculation.image1 === 'string' || immatriculation.image1 instanceof String ? url+immatriculation.image1:URL.createObjectURL(immatriculation.image1),
      image2: typeof immatriculation.image2 === 'string' || immatriculation.image2 instanceof String ? url+immatriculation.image2:URL.createObjectURL(immatriculation.image2),
      image3: typeof immatriculation.image3 === 'string' || immatriculation.image3 instanceof String ? url+immatriculation.image3:URL.createObjectURL(immatriculation.image3),
      image4: typeof immatriculation.image4 === 'string' || immatriculation.image4 instanceof String ? url+immatriculation.image4:URL.createObjectURL(immatriculation.image4),
    });
   setStepChk({...stepChk,step5:isstepValid});
  },[]);
  const onFileChange = (event) => {
    setPreviews({...previews,[event.target.name]:URL.createObjectURL(event.target.files[0])});
    setImmatriculation({...immatriculation,[event.target.name]:event.target.files[0]});
   
  }
  return (
    <div className="step-four step-files">
      <div className="files">
        <div className="file-wrapper">
          <p>Vue de face</p>
          <div className="image">
            <img src={previews.image1 ? previews.image1:''} alt="Photo de face" style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button> 
             <input type="file" name="image1" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

        <div className="file-wrapper">
          <p>Vue de dos</p>
          <div className="image">
            <img src={previews.image2 ? previews.image2:''} alt=""  style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button>
            <input type="file" name="image2" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

        <div className="file-wrapper">
          <p>Vue de profil</p>
          <div className="image">
            <img src={previews.image3 ? previews.image3:''} alt="" style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button>
            <input type="file" name="image3" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

      
        <div className="file-wrapper">
          <p>Fonds de dossier</p>
          <div className='image'>
            {previews.previews4 === '' ? <AiOutlineFile size={50} /> : <BsFillFileEarmarkCheckFill size={50} />}
          </div>
          {/* <div className="image">
            <img src={previews.previews4} alt="" style={{height:'100%'}}/>
          </div> */}
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer le dossier</button>
            <input type="file" name="image4" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>
      </div>
      
      <div className="buttons">
        <button className="secondary" onClick={handlePrevStep}>
          Precedent
        </button>
        <button onClick={handleNextStep} disabled={isstepValid?false:true}>Suivant</button>
      </div>
    </div>
  );
};

export default StepFour;
