import { useState,useContext ,useEffect,useMemo} from "react";
import { useParams,useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Viewer from "react-viewer";
import RejectionModal from "../../components/RejectionModal/RejectionModal";
import { getPaysByID } from '../../utils/helper/functions';
import moment from 'moment';
import 'moment/locale/fr';
import { getReformeByID,ImmatriculationRejeter,ValiderReforme } from "../../services/immatriculation.service";
import Erreurs from "../../components/erreurs/Erreurs";
import { getUserRole } from "../../services/auth.service";
import { getDirectionByID } from "../../services/organisation.service";
import toast from "react-hot-toast";
import { Helmet } from 'react-helmet-async';
import {UserContext} from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
const DetailsReforme = () => {
  moment.locale('fr');
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

  const url = process.env.REACT_APP_URL + '/storage/';
  const [reformeInfo, setReformeInfo] = useState([]);
  const[success,setSuccess] = useState(false);
  const[message,setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [role,setRole] = useState();
  //Image viewer
  const [isVisible, setIsVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const [ancienDirection,setAncienDirection] = useState();
  const [nouvelleDirection,setNouvelleDirection] = useState();
  const[erreurs,setErreurs] = useState([]);
  const [status,setStatus] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const {currentUserPrivilege} = useContext(UserContext);
  const[isvalidedPrivilege,setIsvalidedPrivilege] = useState(false);
   const [isAgent,setisAgent] = useState(false);
   const [raisonRejet,setRaisonRejet] = useState(''); 
  const images = [
    { src: url + reformeInfo?.faceImage },
    { src: url + reformeInfo?.backImage },
    { src: url + reformeInfo?.profileImage },
    { src: url + reformeInfo?.lettreImage }
  ];
  const pays = getPaysByID(reformeInfo.provenance);  
  if(reformeInfo?.lettreImage?.slice(-3) !== 'jpg' && reformeInfo?.lettreImage?.slice(-4) !== 'jpeg'){
     if(images.length > 0 ){
       images.splice(3);
     }
  }
  const handShowRaisonRejet = ()=>{
    const raison = raisonRejet;
    Swal.fire({
      title: 'Motif de Rejet',
      text: raison,
      icon: 'info',
    })
  }
  useMemo(() => {
    setIsLoading(true);
    if( reformeInfo.nouvelleDirection_id !== undefined){    
      getDirectionByID(reformeInfo.ancienDirection_id).then((resp) => {
        setAncienDirection(resp);
      });
      getDirectionByID(reformeInfo.nouvelleDirection_id).then((resp) => {
        setNouvelleDirection(resp);
      });
     
      if(reformeInfo.statusReforme === 0)
        setStatus("En attente");
      else if(reformeInfo.statusReforme === 1)
        setStatus("Validé");
      else if(reformeInfo.statusReforme === 2){
        ImmatriculationRejeter(reformeInfo.immatriculation_id).then((resp) => {
          if(resp.raison != null)
            setRaisonRejet(resp.raison);
          else setRaisonRejet(resp.autreraison);   
        });
        setStatus("Réjeté"); 
      }
      if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
        const result  = currentUserPrivilege.filter(p => p.privilege == "Nouvelle Reforme");
        if(Array.isArray(result) && result.length > 0)
            if(reformeInfo.user_id == result[0].user_id)
                setisAgent(true)
      }
      if(Array.isArray(currentUserPrivilege) && currentUserPrivilege.length > 0){
        const result  = currentUserPrivilege.filter(p => p.privilege == "Validation");
        if(Array.isArray(result) && result.length > 0)
           setIsvalidedPrivilege(true)
      }  
  }
  setIsLoading(false);
  },[reformeInfo?.vehicule_id])
  const getDetails = async (currentID) => {
    setIsLoading(true);
    getReformeByID(currentID).then((resp) => {
       if(resp.success){
          setSuccess(true);
          setReformeInfo(resp.reformes[0]);
          setIsLoading(false);
       } else {
          setIsLoading(false);
          setMessage("Nous n'avons pas trouvé de donnée correspondante");
       } 
    })
  }

  const handleImageClick = (index) => {
    setImageIndex(index);
    setIsVisible(true);
  };

  useEffect(() => {
    setIsLoading(true);
       getUserRole().then(resp=> setRole(resp)); 
       getDetails(id);    
    setIsLoading(false);    
  }, [id]);

  const handleValidation = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('reforme_id',id);
    Swal.fire({
      title: "Voulez-vous valider cette reforme ?",
      text: "Vous ne pourrez plus revenir en arrière",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a9d8f",
      cancelButtonColor: "#e63946",
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
          ValiderReforme(formData).then((resp) => {
             setIsLoading(false);
             if( resp.success === true ){
              toast.success("Reforme Effectuée avec succès");
              navigate('/liste-reforme');
             }else{
              toast.error("Erreur"); setErreurs(resp.messages);
             }
          })
      }
    });
  };
  
  return (
    <div className="details-immatriculation page">
      <Helmet>
        <title>Détails de la reforme</title>
      </Helmet>
      { success == true ? 
      <div>
        <RejectionModal
          isOpen={isRejectionModalOpen}
          setIsOpen={setIsRejectionModalOpen} id={id} type="reforme"
        />
        {isLoading && <Spinner />}
        <h2>Détails de la reforme </h2>
        <div className="infos">
          <h3>Infos du vehicule</h3>
          <div className="info-group">
            <div>
              <span>Numero d'immatriculation</span>
              <strong>{reformeInfo?.immatriculation_number}</strong>
            </div>
            <div>
              <span>Provenance</span>
              <strong>{pays}</strong>
            </div>
            <div>
              <span>Genre de véhicule</span>
              <strong>{reformeInfo?.genre}</strong>
            </div>
            <div>
              <span>Type</span>
              <strong>{reformeInfo?.typeVehicule}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Marque</span>
              <strong>{reformeInfo?.marque}</strong>
            </div>
            <div>
              <span>Modèle</span>
              <strong>{reformeInfo?.model}</strong>
            </div>
            <div>
              <span>Année</span>
              <strong>{reformeInfo?.madeYear}</strong>
            </div>
            <div>
              <span>Carosserie</span>
              <strong>{reformeInfo?.carosserie}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Energie</span>
              <strong>{reformeInfo?.energy}</strong>
            </div>
            <div>
              <span>Numero de chassis</span>
              <strong>{reformeInfo?.numChassie}</strong>
            </div>
            <div>
              <span>Nombre de portes</span>
              <strong>{reformeInfo?.nbPorte}</strong>
            </div>
            <div>
              <span>Places assises</span>
              <strong>{reformeInfo?.placeNumberAssis}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Places debout</span>
              <strong>{reformeInfo?.placeNumberDebout}</strong>
            </div>
            <div>
              <span>Premiere mise en cirulation</span>
              <strong>{moment(reformeInfo?.releaseYear).format("DD-MMMM-YYYY")}</strong>
            </div>
            <div>
              <span>Ancien numero d'immatriculation</span>
              <strong>{reformeInfo?.ancienImmatriculation}</strong>
            </div>
            <div>
              <span>Kilometrage</span>
              <strong>{reformeInfo?.kilometrage}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Cylindre</span>
              <strong>{reformeInfo?.cylinderNumber}</strong>
            </div>
            <div>
              <span>Nombre d'essieux</span>
              <strong>{reformeInfo?.nbreEssuie != null?reformeInfo?.nbreEssuie:0}</strong>
            </div>

            <div>
              <span>Poids à vide</span>
              <strong>{reformeInfo?.pv != null?reformeInfo?.pv:0}</strong>
            </div>
            <div>
              <span>Charge Utile</span>
              <strong>{reformeInfo?.cu != null ? reformeInfo?.cu : 0}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Poids total autorisé</span>
              <strong>{reformeInfo.pv != null && reformeInfo.cu != null ? parseInt(reformeInfo.pv) + parseInt(reformeInfo.cu):0}</strong>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className="info-group images-group">
            <div>
              <span>Photo de face</span>
              <div className="image" onClick={() => handleImageClick(0)}>
                <img src={reformeInfo?.faceImage !== undefined  ? url + reformeInfo?.faceImage:""} alt="" id="image" />
              </div>
            </div>
            <div>
              <span>Photo de dos</span>
              <div className="image" onClick={() => handleImageClick(1)}>
                <img src={reformeInfo?.backImage !== undefined  ? url + reformeInfo?.backImage:""} alt="" />
              </div>
            </div>
            <div>
              <span>Photo de profil</span>
              <div className="image" onClick={() => handleImageClick(2)}>
                <img src={reformeInfo?.profileImage !== undefined  ? url + reformeInfo?.profileImage:""} alt="" />
              </div>
            </div>
            <div>
              <span>Fond de Dossier</span>
              {(reformeInfo?.lettreImage.slice(-3) === 'jpg' || reformeInfo?.lettreImage.slice(-4) === 'jpeg') &&
              <div className="image" onClick={() => handleImageClick(3)}>
                <img src={reformeInfo?.lettreImage !== undefined  ? url + reformeInfo?.lettreImage:""} alt="" />
              </div>}
              {(reformeInfo?.lettreImage.slice(-3) !== 'jpg' && reformeInfo?.lettreImage.slice(-4) !== 'jpeg') &&
                <div className='image' onClick={() => {if(reformeInfo?.lettreImage.slice(-3) === 'pdf') window.open(`${url}${reformeInfo?.lettreImage}`);}}>
                   <BsFillFileEarmarkCheckFill size={100} />
                </div>
              }  
            </div>
            <div></div>
          </div>

          <h3>Ancienne attribution</h3>
          <div className="info-group">
            <div>
              <span>Ancienne affectation</span>
              <strong>{reformeInfo?.ancienMinistere}</strong>
            </div>
            <div>
              <span>Ancienne direction</span>
              <strong>{ancienDirection}</strong>
            </div>
            <div>
              <span>Date d'attribution</span>
              <strong>{moment(reformeInfo?.ancienDteAttribution).format("DD-MMMM-YYYY")}</strong>
            </div>
            <div>
              <span>Valeur Residuelle</span>
              <strong>{reformeInfo?.valeurResiduelle ? reformeInfo?.valeurResiduelle + " FG":"Non Fournie"}</strong>
            </div>
          </div>

          <h3>Nouvelle reforme</h3>
          <div className="info-group">
            <div>
              <span>Departement</span>
              <strong>{reformeInfo?.NouveauMinistere}</strong>
            </div>
            <div>
              <span>Direction</span>
              <strong>{nouvelleDirection}</strong>
            </div>
            <div>
              <span>Date de reforme</span>
              <strong>{moment(reformeInfo?.nouvelleDateReforme).format("DD-MMMM-YYYY")}</strong>
            </div>
            <div>
              <span>Status</span>
              {raisonRejet != ''?
                  <strong style={{color:'red',cursor:'pointer',textDecoration:'underline'}} onClick={handShowRaisonRejet}> {status} </strong> 
                  :
                  <strong> {status} </strong> 
               }
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Nom</span>
              <strong>{reformeInfo?.nomProprietaire}</strong>
            </div>
            <div>
              <span>Prenom</span>
              <strong>{reformeInfo?.PrenomProprietaire}</strong>
            </div>
            <div>
              <span>Date de naissance</span>
              <strong>{moment(reformeInfo?.date_naissance).format("DD/MM/YYYY")}</strong>
            </div>
            <div>
              <span>Fonction</span>
              <strong>{reformeInfo?.fonction}</strong>
            </div>
          </div>

          <div className="info-group">
            <div>
              <span>Pièce d'identité</span>
              <div className="image" onClick={() => handleImageClick(4)}>
                <img src={reformeInfo?.piece !== undefined  ? url + reformeInfo?.piece:""} alt="" />
              </div>
            </div>
            <div>
              <span>Reçu de paiement</span>
              <div className="image" onClick={() => handleImageClick(5)}>
                <img src={reformeInfo?.paiement !== undefined  ? url + reformeInfo?.paiement:""} alt="" />
              </div>
            </div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="image-viewer">
          <Viewer
            visible={isVisible}
            onClose={() => {
              setIsVisible(false);
            }}
            images={images}
            activeIndex={imageIndex}
          />
        </div>
        {
          (isAgent && reformeInfo.statusReforme === 2) &&
          <>
            <div className="buttons">
              <Link to='/liste-reforme'>
                  <button className="secondary">
                    Retour
                  </button>
                  
              </Link>
                <Link to={`/resoumission-reforme/${reformeInfo.reforme_id}`}>
                   <button className="primary">
                        Resoumettre
                   </button>
             
                </Link>
            </div>    
          </>
        
        }
        {(isvalidedPrivilege && reformeInfo.statusReforme == 0)  &&
         <>
            <Erreurs validation = {erreurs} />
            <div className="buttons">
            <button className="validate" onClick={ handleValidation}>
              Valider
            </button>
            <button
              className="reject-btn"
              onClick={() => setIsRejectionModalOpen(true)}
            >
              {" "}
              Rejeter{" "}
            </button>
          </div>
         </>
        
           }
        </div>
    
      :<>{message}</>
      }
    </div>
  );
};

export default DetailsReforme;