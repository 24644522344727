import { useEffect, useState, useMemo } from "react";
import { BiUpload } from "react-icons/bi";
import { getConnexionFormData } from "../services/auth.service";
// import FormData from 'form-data'
// import { handleInputChange } from 'react-select/dist/declarations/src/utils';

const UploadImage = () => {
  // const [previews,setPreviews] = useState({previews1:'',previews2:'',previews3:'',previews4:''});
  // const isstepValid = useMemo(() => {
  //   return immatriculation.image1 != '' && immatriculation.image2 != ''
  //          && immatriculation.image3 != '' && immatriculation.image4 != ''
  // },[immatriculation]);
  // useEffect(() => {
  //    setPreviews({...previews,
  //     // previews1: immatriculation.image1 !='' ? URL.createObjectURL(immatriculation.image1):'',
  //     // previews2: immatriculation.image2 != '' ? URL.createObjectURL(immatriculation.image2):'',
  //     // previews3: immatriculation.image3 != '' ? URL.createObjectURL(immatriculation.image3):'',
  //     // previews4: immatriculation.image4 !='' ? URL.createObjectURL(immatriculation.image4):'',
  //   });
  //  setStepChk({...stepChk,step5:isstepValid});
  // },[immatriculation]);
  // const onFileChange = (event) => {
  //   setImmatriculation({...immatriculation,[event.target.name]:event.target.files[0]});

  //console.log(previews.previews1);
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("lettreImage", form.lettreImage);
    formData.append("faceImage", form.faceImage);
    formData.append("profileImage", form.profileImage);
    formData.append("backImage", form.backImage);
    alert("Envoyer");
    console.log("FORM => ", formData.getAll());
    getConnexionFormData().post("/immatriculations/vehicule/1", formData).then(res =>
      console.log(res)
    )

  }

  const  [form, setForm ]= useState({});

  function handleInputChange(e) {
  
    setForm({
      ...form,
      [e.target.name]:  e.target.files[0]
    })
    // form[e.target.name] = e.target.files[0];
    
  }
  console.log(form);


  return (
    <form className="upload-btn-wrapper" onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
      <input type="file" name="lettreImage" onChange={handleInputChange} />
      <input
        type="file"
        name="faceImage"
        onChange={(e) => handleInputChange(e)}
      />
      <input
        type="file"
        name="profileImage"
        onChange={(e) => handleInputChange(e)}
      />
      <input
        type="file"
        name="backImage"
        onChange={(e) => handleInputChange(e)}
      />
      <input type="submit" />
    </form>
  );
};

export default UploadImage;
