import { useState ,useEffect,useMemo} from "react";
import { useForm } from "react-hook-form";
import {getCarInformation} from "../../services/vehicule.service";
import Spinner from "../Spinner/Spinner";
import { colors } from "../../utils/colors";
import { getPays ,isEmpty, isDateValid,checkValid,validateDate} from '../../utils/helper/functions';
import { carosseries } from "../../services/utils/carosserie";

const StepTwo = ({ handleNextStep, handlePrevStep,immatriculation,setImmatriculation,stepChk,setStepChk ,paiement,erreurs,setStep}) => {
    const { register, handleSubmit, reset,formState: { errors } } = useForm({
        defaultValues:{
            immatriculation
        }
    });
  const [currentYear,setCurrentYear] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const [modeles,setModeles] = useState([]);
  const [genres,setGenres] = useState([]);
  const [types,setTypes] = useState([]);
  const [ptc,setPtc] = useState(0);
  const isstepValid = useMemo(() => {
      return checkValid(immatriculation);
  },[immatriculation]);
  
    useEffect(()=>{
        // console.log(erreurs)
        // if(typeof erreurs !== 'undefined')
        //    setStep(1);

        setStepChk({...stepChk,step2:isstepValid});
        setCurrentYear(new Date().getFullYear());
        if(immatriculation.genre != 0){
            const seletedTypes  = immatriculation.categories.AllTypes? immatriculation.categories.AllTypes.filter(g => g.genre_id == immatriculation.genre):[];
            setTypes(seletedTypes); 
        }
        if(immatriculation.marque != 0){
            const modeleSelected = immatriculation.brands.allModeles? immatriculation.brands.allModeles.filter(g => g.marque_id == immatriculation.marque):[];
            setModeles(modeleSelected);
        }
        if(immatriculation.genre != 0){
            const seletedTypes  = immatriculation.categories.AllTypes? immatriculation.categories.AllTypes.filter(g => g.genre_id == immatriculation.genre):[];
            setTypes(seletedTypes); 
        }
      reset(immatriculation)
    },[isstepValid]);
  
  
  const pays = getPays();
 
  const marqueIDByName = (marqueName) =>{
       const brand = immatriculation.brands.allMarques.filter(m => m.title.toUpperCase() == marqueName.toUpperCase());
       if(Array.isArray(brand))
           return brand[0].id
        else return 0;
  }
  const modeleIDByName = (modeleName) => {
    const brand = immatriculation.brands.allModeles.filter(m => m.title.toUpperCase() == modeleName.toUpperCase());
    if(Array.isArray(brand))
        return brand[0].id
     else return 0;
  }
  const handleInput = (e) => {
    if(e.target.type != 'select-one'){ 
        if(e.target.name == 'numChassie'){
            if(e.target.value.length <= 17){
              setImmatriculation({...immatriculation,[e.target.name]:e.target.value.toUpperCase()});
            }
        }else{ 
            setImmatriculation({...immatriculation,[e.target.name]:e.target.value.toUpperCase()});
        }
    }
    else {setImmatriculation({...immatriculation,[e.target.name]:e.target.value}); return ;}   

    if(e.target.name == 'numChassie')
    {
        if( e.target.value.length === 170 )
        {
            setIsLoading(true);
            getCarInformation(e.target.value.length).then((data) => {
                console.log(data);
                if(data !== null ){
                  const marqueID = marqueIDByName(data.make);
                  const modeleID = modeleIDByName(data.model);  
                  setImmatriculation({...immatriculation,marque:marqueID,annee:data.year,nbPlaceAssise:data.standard_seating,carrosserie:data.style,model:modeleID,transmission:data.transmission});
                }
                setIsLoading(false);
            });
        }
    }
   }
  const handleModel = (e) =>{
      setModeles([]);
      setImmatriculation({...immatriculation,marque:e.target.value,model:0}); 
      if(e.target.value != 0 ){
        setIsLoading(true);
        const modeleSelected = immatriculation.brands.allModeles? immatriculation.brands.allModeles.filter(g => g.marque_id == e.target.value):[];
        setModeles(modeleSelected);
        setIsLoading(false);

    }
   }
   const handleGenre = (e) => {
    setImmatriculation({...immatriculation,genre:e.target.value}); 
    setTypes([]);
    if(e.target.value != 0 ){
      setIsLoading(true);
      const seletedTypes  = immatriculation.categories.AllTypes? immatriculation.categories.AllTypes.filter(g => g.genre_id == e.target.value):[];
      setTypes(seletedTypes); 
      setIsLoading(false);
    }
   }

    return (
        <div className="step-two">
            {isLoading && <Spinner />}
            <form onSubmit={handleSubmit(handleNextStep)}>
             <div className="error-msg">{erreurs}</div>
                <div className="input-group">
         
                     <label> Numero de chassis
                            <input value={immatriculation.numChassie}
                            readOnly
                            type="text" name="numChassie" 
                            placeholder="Numero de chassis" 
                            {...register('numChassie', {
                                onChange: (e) => {
                                     handleInput(e)
                                },
                            required:true,maxLength:17,minLength:3},
                            )}
                            />
                            {errors.numChassie && errors.numChassie?.type === "required" && (
                                    <span className="error-msg">Le Numéro de Chassis est obligatoire.</span>
                                )}
                            {errors.numChassie && errors.numChassie?.type === "minLength" && (
                                <span className="error-msg">Le caractère minimum est trois (3).</span>
                            )}
                            {errors.numChassie && errors.numChassie?.type === "maxLength" && (
                                <span className="error-msg">Le caractère maximum est dix sept (17).</span>
                            )}
                    
                    </label>
                
                    
                    <label>Genre de véhicule
                    {/* {immatriculation.modeImmatriculation !== "EP" && immatriculation.modeImmatriculation !== "VA"?     */}
                     <select name="genre" value={immatriculation.genre} 
                     disabled
                       id="genre" 
                       {...register('genre', {
                        onChange: (e) => {
                            handleGenre(e)
                        },
                        validate: (value) => value != 0 
                        },
                      )}
                       >
                        <option value={0}>Genre de véhicule</option>
                        {
                           immatriculation.categories.AllGenres.length > 0 && immatriculation.categories.AllGenres.map((genre) => {
                              return <option key={Math.random()+genre.genre_id} value={genre.genre_id}>{genre.nom}</option>
                          })
                        } 
                     </select>
               
                    {errors.genre && (
                             <span className="error-msg">Le Genre de vehicule est obligatoire.</span>
                          )}
                    </label>

                    <label>Type
                    <select name="type" value={immatriculation.type} id="type"
                     {...register('type', {
                        onChange: (e) => {
                            handleInput(e);
                        },
                        validate: (value) => value !== '0'
                        },
                      )}
                     >
                        <option value={'0'}>Type de Vehicule</option>
                        {
                            Array.isArray(types) && types.length > 0 && types.map((type) => {
                                return <option key={Math.random()+type.type_id} value={type.type_id}>{type.nom}</option>
                            })
                        }
                    </select>
                    {errors.type && (
                             <span className="error-msg">Le Type de vehicule est obligatoire.</span>
                          )}
                    </label>

                    <label>Mode d'acquisition
                    <select name="acquisition" id="acquisition" value={immatriculation.acquisition} onChange={handleInput}>
                        <option value="achat" key={Math.random()+'Achat'}>Achat</option>
                        <option value="don" key={Math.random()+'don'}>Don</option>
                        <option value="privé" key={Math.random()+'don'}>Privé</option>
                    </select>
                    </label>
                </div>

                <div className="input-group">
                    <label htmlFor="marque">Marque
                    <select name="marque" value={immatriculation.marque} id="marque"
                    {...register('marque', {
                        onChange: (e) => {
                            handleModel(e)
                        },
                        validate: (value) => value != 0 
                        },
                      )}
                    >
                       { <option value ={0}>Selectionner la marque</option>  }
                       {
                           Array.isArray(immatriculation.brands.allMarques) && immatriculation.brands.allMarques.length > 0 && immatriculation.brands.allMarques.map((marque) =>{
                             
                                return <option key={Math.random()+marque.id} value={marque.id}>{marque.title}</option>
                            })

                        }
                        
                    </select>
                    {errors.marque && (
                             <span className="error-msg">La marque du vehicule est obligatoire.</span>
                          )}
                    </label>
                    <label  htmlFor="model">Modèle
                        <select name="model" value={immatriculation.model} id="model"
                         {...register('model', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                            validate: (value) => value !== '0' 
                            },
                          )}
                         >
                            <option value={'0'}>Selectionner le modèle</option>
                            {                              
                                modeles.length > 0 && modeles.map((modele) => {
                                    return(
                                        <option  key={Math.random()+modele.id} value={modele.id}> {modele.title} </option>
                                    )
                                  
                                })
                            }
                        </select>
                        {errors.model && (
                             <span className="error-msg">Le modele du vehicule est obligatoire.</span>
                          )}
                    </label>
                    <label>Carosserie
                    <select name="carrosserie" id="carrosserie" value={immatriculation.carrosserie} 
                       {...register('carrosserie', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != 0 
                        },
                      )}
                    >
                        <option value={0}>Selectionner la Carrosserie</option>
                        {
                            carosseries.length > 0 && carosseries.map((carosserie) => {
                                return  <option key={Math.random()+carosserie.id} value={carosserie.id}>{carosserie.nom}</option>
                            })
                        }
                    </select>
                    {errors.carrosserie && (
                             <span className="error-msg">La Carosserie du vehicule est obligatoire.</span>
                          )}
                    </label>
                    
                    <label>Année de Fabrication
                    <input 
                      value={immatriculation.annee} 
                      type="number"
                      name="annee" id="annee" 
                      placeholder="Année"
                      {...register('annee', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                         required:true,min:1900,max:currentYear },
                       )}
                     />
                   
                    {errors.annee && errors.annee?.type === "required" && (
                                <span className="error-msg">L'année est obligatoire.</span>
                        )}
                        {errors.annee && errors.annee?.type === "min" && (
                            <span className="error-msg">L'année minimale est 1900.</span>
                        )}
                        {errors.annee && errors.annee?.type === "max" && (
                            <span className="error-msg">L'année maximale est {currentYear} .</span>
                        )}
                    </label>

                    
                </div>
                <div className="input-group">
                  <label>Mode d'immatriculation
                     <input name="modeImmatriculation" readOnly
                      value={immatriculation.modeImmatriculation}
                     />
                  </label>
                  <label>
                        Couleur
                        <select name="couleur" id="colors" className="colors"
                            value={immatriculation.couleur} placeholder="Selectionner la couleur"
                            {...register('couleur', {
                                onChange: (e) => {
                                    handleInput(e)
                                },
                                validate: (value) => value != ''},
                            )}
                        >
                        <option value=''>Selectionner la couleur</option>    
                        {colors.map((element) => {
                            return (
                            <option
                                value={element.name}
                                key={Math.random()+element.id}
                                style={{
                                backgroundColor: element.hex,
                                color: element.name === "Noir" ? "#fff" : "#000",
                                }}
                            >
                                {element.name}
                            </option>
                            );
                        })}
                        </select>
                        {errors.couleur && (<span className="error-msg">Veuillez choisir la Couleur.</span>)} 
                    </label>
                    <label>Mode de transmission
                        <select name="transmission" id="transmission"
                           value={immatriculation.transmission} 
                           {...register('transmission', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                            validate: (value) => value != '' },
                           )}
                           >
                           
                            <option value="Manuelle">Manuelle</option>
                            <option value="Automatic">Automatique</option>
                        </select>
                        {errors.transmission && (
                            <span className="error-msg"> Le mode de transmission est Obligatoire.</span>
                        )} 
                    </label>
                    <label>Energie
                    <select name="energie" id="energie" value={immatriculation.energie}  onChange={handleInput}>
                        <option value={0}>Source d'énergie</option>
                        <option value="Essence">Essence</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Electrique">Electrique</option>
                        <option value="Hybride">Hybride</option>
                    </select>
                    </label>
                </div>
                <div className="input-group">
                   <label> Première date de mise en circulation
                        <input type="date" name='dateP' value={immatriculation.dateP} 
                            placeholder="Première mise en circulation"  
                            {...register('dateP', {
                                onChange: (e) => {
                                    handleInput(e)
                                },
                                  validate: (value) => isDateValid(value) && validateDate(value)},
                               )}
                        />
                        {errors.dateP && (
                            <span className="error-msg"> La date choisie n'est pas valide </span>
                        )} 
                    </label>
                   
                    <label> Nombre de portes
                        <input
                           type="number" 
                           value={immatriculation?.nbPorte} 
                           min="0"
                           name="nbPorte" placeholder="Nombre de portes" 
                           readOnly={(paiement.nbPorte !== 0 && paiement.nbPorte !== '' && typeof paiement.nbPorte !== 'undefined')}
                           {...register('nbPorte', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                           required:true,min:0},
                           )}
                          />
                          {errors.nbPorte && errors.nbPorte?.type === "required" && (
                                 <span className="error-msg">Le Nombre de porte est obligatoire.</span>
                            )}
                          {errors.nbPorte && errors.nbPorte?.type === "min" && (
                                 <span className="error-msg">Le nombre minimum pour la porte est zéro (0).</span>
                            )}   
                    </label>
                    <label> Places assises
                        <input min="1" type="number" 
                            value={immatriculation.nbPlaceAssise} 
                            name="nbPlaceAssise"
                            placeholder="Nombre de places assises" 
                            {...register('nbPlaceAssise', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                            required:true,min:1},
                            )}
                        />
                        
                          {errors.nbPlaceAssise && errors.nbPlaceAssise?.type === "required" && (
                                 <span className="error-msg">Le Nombre de place assises est obligatoire.</span>
                            )}
                          {errors.nbPlaceAssise && errors.nbPlaceAssise?.type === "min" && (
                                 <span className="error-msg">Le nombre minimum  de place assises est un (1).</span>
                            )}   
                    </label>
                    <label> Places debout
                        <input min="0" 
                          type="number" 
                          value={immatriculation?.nbPlaceDebout} 
                          name="nbPlaceDebout" 
                          placeholder="Nombre de place debout"  
                          {...register('nbPlaceDebout', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                           required:true,min:0},
                           )}
                          />
                          {errors.nbPlaceDebout && errors.nbPlaceDebout?.type === "required" && (
                                 <span className="error-msg">Le Nombre de place debout est obligatoire.</span>
                            )}
                          {errors.nbPlaceDebout && errors.nbPlaceDebout?.type === "min" && (
                                 <span className="error-msg">Le nombre minimum  de place debout est un Zero(0).</span>
                            )}   
                    </label> 
                   
                </div>

                <div className="input-group">
                    <label>Provenance
                    <select name="idpays" id="provenance" 
                        value={immatriculation.idpays} onChange={handleInput}
                        {...register('idpays', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                             validate: (value) => value != 0 },
                           )}
                     >
                    <option value={0}>Selectionner la Provenance</option>
                     {
                          !isEmpty(pays) &&  Object.keys(pays).map((value) =>{
                             return <option key={Math.random()+value} value={value}>{pays[value]}</option>
                          })
                       
                     }     
                    </select>
                    {errors.idpays && (
                            <span className="error-msg"> Le Pays d'orgine est obligatoire.</span>
                        )} 
                    </label>
                    <label> Ancien numéro d'immatriculation
                        <input type="text" 
                           value={immatriculation.ancienNumMat} 
                           name="ancienNumMat"  
                           placeholder="ex: RC-1234-A"
                           {...register('ancienNumMat', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                             pattern:{
                                 value: /[A-Z]{2,3}-[0-9]{4}-[A-Z]{1,2}/,
                                 message:"Respectez le format d'immatriculation"
                             }
                            },
                           )}
                          /> 
                         {errors.ancienNumMat && (
                             <span className="error-msg">{errors.ancienNumMat.message}</span>
                         )}
                    </label>
                    <label> Kilometrage
                        <input min="0" 
                        type="number" 
                        value={immatriculation.kilometrage}  
                        readOnly={(paiement?.kilometrage !== 0 && paiement?.kilometrage !== "" && typeof paiement?.kilometrage!== 'undefined')}
                        name="kilometrage" 
                        placeholder="Kilometrage" 
                        {...register('kilometrage', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                             min:0},
                           )}
                        />
                        {errors.kilometrage && errors.kilometrage?.type === "min" && (
                                <span className="error-msg">Le minimum est 0.</span>
                        )}
                    </label>
                    <label> Cylindre
                        <input min="0" max="20" 
                         type="number" 
                         value={immatriculation.cylindre} 
                         readOnly={(paiement.cylindre !== '0' && paiement.cylindre !== '' && typeof paiement.cylindre !== 'undefined')}
                         placeholder="Cylindre"  name="cylindre" 
                         {...register('cylindre', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                             required:true,min:1,max:20},
                           )}
                         />
                        {errors.cylindre && errors.cylindre?.type === "required" && (
                            <span className="error-msg">Le Nombre de cylindre est obligatoire.</span>
                        )}
                        {errors.cylindre && errors.cylindre?.type === "min" && (
                            <span className="error-msg">Le nombre minimum  de cylindre est un (1).</span>
                        )}   
                        {errors.cylindre && errors.cylindre?.type === "max" && (
                        <span className="error-msg">Le nombre maximum de cylindre est vingt (20).</span>
                        )}  
                    </label>
                </div>
                <div className="input-group">
                  
                   <label>Nombre d'essieux
                       <input type='number' name="nbreEssuie"  min='0'
                        value={immatriculation?.nbreEssuie}
                        readOnly={(paiement?.nbreEssuie !== '0' && paiement?.nbreEssuie !== '' && typeof paiement?.nbreEssuie !== 'undefined')}
                         placeholder="Nombre d'essieux"
                         {...register('nbreEssuie', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                            //  required:false,max:4
                            required:false
                            },
                           )}
                       />
                       {/* {errors.nbreEssuie && errors.nbreEssuie?.type === "max" && (
                           <span className="error-msg">Le nombre maximum  d'essieux est quatre(4).</span>
                       )}         */}
                   </label>
               
                   <label>Poids à vide(Kilogramme)
                     <input type='number' name="pv"  min='0' 
                        value={immatriculation?.pv}
                        readOnly={(paiement.pv !== 0 && paiement.pv !== '')}
                       {...register('pv', {
                           onChange: (e) => {
                               handleInput(e)
                           },
                            required:true,min:1,},
                          )}
                      />
                     
                        {errors.pv && errors.pv?.type === "required" && (
                            <span className="error-msg">Le poids à vide est obligatoire.</span>
                        )}
                        {errors.pv && errors.pv?.type === "min" && (
                            <span className="error-msg">Le nombre minimum  du poids à vide est un (1).</span>
                        )}
                   </label>
                   <label>Charge Utile(Kilogramme)
                       <input type='number' name="cu" min='0' 
                         value={immatriculation?.cu} 
                         readOnly={(paiement?.cu !== 0 && paiement?.cu !== '')}
                        {...register('cu', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                             required:true,min:1,},
                           )}
                       />
                        {errors.cu && errors.cu?.type === "required" && (
                            <span className="error-msg">La charge utile est obligatoire.</span>
                        )}
                        {errors.cu && errors.cu?.type === "min" && (
                            <span className="error-msg">Le nombre minimum  pour la charge utile est un (1).</span>
                        )}
                   </label>
                   <label>Poids total Autorisé à Charge
                       <strong style={{paddingTop:'7px'}}>{immatriculation.cu && immatriculation.pv ? parseInt(immatriculation.pv) + parseInt(immatriculation.cu):0}</strong>
                  </label> 
                </div>
                <div className="input-group">
                    <div>
                        <label>Puissance Administrative(En CV)
                        <input type='number' name='pa' 
                         min='0'
                         value={immatriculation?.pa}
                         readOnly={(paiement.pf !== 0 && paiement.pf !== '' && typeof paiement.pf !== 'undefined')}
                            {...register('pa', {
                                onChange: (e) => {
                                    handleInput(e)
                                },
                                required:true,min:1,},
                            )}
                        />
                        {errors.pa && errors.pa?.type === "required" && (
                                <span className="error-msg">La Puissance Administrative est Obligatoire.</span>
                            )}
                            {errors.pa && errors.pa?.type === "min" && (
                                <span className="error-msg">La Puissance Administrative  ne peut pas être nulle.</span>
                            )}
                       </label> 
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <div className="buttons">
                    <button className="secondary" onClick={handlePrevStep}>Precedent</button>
                    <button type="submit">Suivant</button>
                </div>
            </form>
        </div>
    );
}
 
export default StepTwo;