import {useMemo,useEffect,useState} from 'react';
import {getministeres,getdirectionsByMinistere} from '../../services/organisation.service';
import { useForm } from "react-hook-form";
import Spinner from "../Spinner/Spinner";

const StepThree = ({ handleNextStep, handlePrevStep,immatriculation,setImmatriculation,stepChk,setStepChk }) => {
  const [nameOfInput,setNameOfInput] = useState();
  const handleInput = (e) => {
    setNameOfInput(e.target.name);
    setImmatriculation({...immatriculation,[e.target.name]:e.target.value})
  }
  const { register, handleSubmit,reset, formState: { errors } } = useForm({
    defaultValues:{
      immatriculation
    }
  });
  //const[ministeres,setMinisteres] = useState([]);
  const [directions,setDirections] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const isstepValid = useMemo(() => {
     return immatriculation.ministere != 0 ;
  },[immatriculation]);

  useEffect(()=>{
    setStepChk({...stepChk,step4:isstepValid});
    reset(immatriculation);
    let ministere_id = 0;
    if(typeof immatriculation.ministere === 'number')
      ministere_id = parseInt(immatriculation.ministere);
    const directionsSelected = immatriculation.organisations.allDirections ? 
    immatriculation.organisations.allDirections.directions.filter(m => m.ministere_id === ministere_id):[];
    setDirections(directionsSelected);
   
},[isstepValid]);
const handleMinistere = (e) =>{
  setDirections([]);
  setImmatriculation({...immatriculation,ministere:e.target.value,direction:0}); 
  if(e.target.value != 0 ){
    setIsLoading(true);
    const directionsSelected = immatriculation.organisations.allDirections ? 
    immatriculation.organisations.allDirections.directions.filter(m => m.ministere_id == e.target.value):[];
    setDirections(directionsSelected);
    setIsLoading(false);
   }
}
 return (
    <div className="step-one">
      {isLoading && <Spinner />}
      <form onSubmit={handleSubmit(handleNextStep)}>
     
        <div className="input-group">
        <label>
          A quel ministère voulez-vous affecter ce véhicule ?
          <select id="ministère" name="ministere"
            value={immatriculation.ministere} 
            {...register('ministere', {
              onChange: (e) => {
                  handleMinistere(e)
                  // setSelectedMinistere(e.target)
              },
               validate: (value) => value != 0 },
             )}
            >
            <option value={0}>Selectionner le Ministère</option>
            {
              immatriculation.organisations.allMinisteres.length > 0 && immatriculation.organisations.allMinisteres.filter((m) => m.typeorganisme.toLowerCase() === immatriculation?.typeOrganisme?.toLowerCase()).map((ministere) => {
                return (<option key={ministere.ministere_id} value={ministere.ministere_id}>{ministere.nom}</option>);
              }) 
            }
           <option value="1000000">Ajouter Autre Ministère</option>
          </select>
          {errors.ministere && (
                 <span className="error-msg"> Selectionnez le ministères!</span>
              )} 
        </label>
       
          <label>Direction ou Service
                <select id="direction" name="direction"
                    value={immatriculation.direction}
                    {...register('direction', {
                      onChange: (e) => {
                          handleInput(e)
                      },
                       },
                      )}
                    >
                    <option value={0}>Selectionner la Direction ou Service</option> 
                    {
                      directions.length > 0 && directions.map((direction) => {return (<option key={direction.direction_id} value={direction.direction_id}>{direction.nom}</option>)})
                    }  
              </select> 
              {/* {errors.direction && errors.direction?.type == "required" && (
                  <span className="error-msg">Veuillez Indiquer le nom de la direction du Departement.</span>
              )}     */}
          </label>
         
        </div>
        {immatriculation.ministere == 1000000 &&  
            <div className="input-group">
              <label> 
                    Autre Ministère
                      <input type="text" defaultValue={immatriculation.autreministere}  
                        name="autreministere" 
                        placeholder="Autre Ministère"
                        {...register('autreministere', {
                          onChange: (e) => {
                              handleInput(e)
                          },
                           required:true },
                         )}
                        />  
                   {errors.observation && errors.observation?.type == "required" && (
                     <span className="error-msg">Veuillez renseigner ce champ .</span>
                   )}      
                </label>
                <label> 
                    Autre Direction
                      <input type="text" defaultValue={immatriculation.autredirection}  
                        name="autredirection" 
                        placeholder="Autre Direction"
                        {...register('autredirection', {
                          onChange: (e) => {
                              handleInput(e)
                          },
                           minLength:2 },
                         )}
                        />  
                  {errors.autredirection && errors.autredirection?.type == "minLength" && (
                     <span className="error-msg">Le Caractère minimum est deux (2).</span>
                  )}      
                </label>
            </div>
           }        
        <div className="buttons">
          <button className="secondary" onClick={handlePrevStep}>Precedent</button>
          <button type='submit'>Suivant</button>
        </div>
      </form>
    </div>
  );
};

export default StepThree;
