import { useState,useEffect,useContext } from "react";
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { ImmatriculationsData } from "../../utils/data/fakeData";
import { getAllReformes } from "../../services/immatriculation.service";
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/fr';
import { Helmet } from 'react-helmet-async'

const ListeReforme = () => {  
  const options = (params) => {
    const id = params.row.id;
    return (
      <div className="options">
        <Link to={`/details-reforme/${id}`}>
          <button>
            voir détails
          </button>
        </Link>
      </div>
    );
  };
  const {ministeres} = useContext(ComboContext); 
  const [immaData,setImmaData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    getReformes();
  }, [ministeres]);

 
  async function getReformes(){
    setIsLoading(true);
      setImmaData([]);
      if( Array.isArray(ministeres) && ministeres.length > 0 ){
        getAllReformes().then((resp) => {
          let ord = 0;  
          setImmaData(resp.map((data) => {
          var status = 'En attente';
          if(data.status === 1) {
            status = "Validé";
          }else if(data.status === 2){
            status = "Rejeté";
          }
          const ministere = data.ministere_id != 1000000 ? ministeres.filter((m)=>m.ministere_id === data.ministere_id)[0].nom:data.autreministere;
          //console.log(ministere)
          ord = ord + 1;
          return {
            'id': data.reforme_id,
            "Ord": ord,
            'date' : moment(data.updated_at).format('Do MMMM  YYYY'),
            'modeImmatriculation':data.modeImmatriculation, 
            'numImmatriculation':data.immatriculation_number,
            'numChassis' : data.numChassie,
            'marque': data.marque,
            'modele':data.model,
            'ministere':ministere,
            'status': status,
          }
        }));
        }) ;
      }
   setIsLoading(false);
  } 
  const [columnDefs] = useState([
    { headerName: "N°Ord.", field: "Ord",  flex: 1, minWidth: 80 },
    { headerName: "Date", field: "date", flex: 1,   minWidth: 130 },

    { headerName: "Immatriculation", field: "numImmatriculation",  flex:1, minWidth: 180},
    { headerName: "Numero de chassis", field: "numChassis",  flex:1, minWidth: 180 },
    { headerName: "Marque", field: "marque",  flex:1, minWidth: 150},
    { headerName: "Modele", field: "modele", flex:1, minWidth: 150},
    { headerName: "Ministère", field: "ministere",  flex:1, minWidth: 150 },
    { headerName: "Status", field: "status", flex:1, minWidth: 150 },
    { headerName: "Options", field: "options", minWidth:250, renderCell: options}
  ]);

  return (
    <div className="liste-reforme page">
      <Helmet>
        <title>Liste des reformes</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Liste des reformes</h2>
      {immaData.length > 0 ?
       <div className="array">
          <Box sx={{ height: 579, width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar}}
              rows={immaData}
              columns={columnDefs}
              autoPageSize
              pagination
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              />
          </Box>
        </div>: <p>Aucune reforme trouvée.</p>}
      
    </div>
  );
};

export default ListeReforme;
