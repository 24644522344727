import { useState,useContext,useEffect } from "react";

import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { Link } from "react-router-dom";
import { getAllImmatriculationToMutation, getAllImmatriculationValidee, isStatusAttente } from "../../services/immatriculation.service";
import { ComboContext } from "../../services/Context/Contexts";
import Spinner from "../../components/Spinner/Spinner";
import moment from "moment";
import 'moment/locale/fr';
import { Helmet } from 'react-helmet-async'

const Mutation = () => {  
  const [isLoading,setIsLoading] = useState(false);
 
  const [immaData,setImmaData] = useState([]);
  const {ministeres} = useContext(ComboContext); 
  const [isValid,setIsValid] = useState(false);
 
  async function getImmatriculations(){
    setIsLoading(true);
      if( Array.isArray(ministeres) && ministeres.length > 0 ){
          getAllImmatriculationToMutation().then((resp) => {
            let ord = 0;  
            setImmaData(resp.map((data) => {
                let ministere = '';
                if(data?.statusMutation != 0 && data?.statusMutation != 'null'){
                  if(data.minister_id !== 1000000){
                    const ministereName =  ministeres.filter((m)=>m.ministere_id === data.minister_id);
                    if(Array.isArray(ministereName) && ministereName.length > 0){
                      ministere = ministereName[0].nom;
                    }
                  }else
                    ministere = data.autreministere;
                  
                }
                else{
                  if(data.oldministere_id !== 1000000){
                    const ministereName =  ministeres.filter((m)=>m.ministere_id === data.oldministere_id);
                    if(Array.isArray(ministereName) && ministereName.length > 0){
                      ministere = ministereName[0].nom;
                    }
                  }else
                    ministere = data.autreministere;
                  // ministere = data.oldministere_id != 1000000 ? ministeres.filter((m)=>m.ministere_id == data.oldministere_id)[0].nom:data.autreministere;
                }
              
                ord = ord + 1;
              
                return {
                  'id': data.immatriculation_id,
                  "Ord": ord,
                  'date' : moment(data.updated_at).format('Do MMMM  YYYY'),
                  'modeImmatriculation':data.modeImmatriculation, 
                  'numImmatriculation':data.immatriculation_number,
                  'numChassis' : data.numChassie,
                  'marque': data.marque,
                  'modele':data.model,
                  'ministere':ministere,
          
                }
            }));
          }) ; 
      }
    setIsLoading(false); 
  } 

  useEffect(() => {
    getImmatriculations();
  }, [ministeres]);
 
  const options = (params) => {
    const id = params.row.id;
    return (
      <div className="options">
        <Link to={`/nouvelle-mutation/${id}`}>
          <button>
            Muter
          </button>
        </Link>
      </div>
    );
  };

  const [columnDefs] = useState([
    { headerName: "N°Ord.", field: "Ord", flex: 1},
    {
      headerName: "Mode Immat.",
      field: "modeImmatriculation",
      flex: 1
    },
    {
      headerName: "Immatriculation",
      field: "numImmatriculation",
      flex: 1
    },
    {
      headerName: "Numero de chassis",
      field: "numChassis",
      flex: 1
    },
    { headerName: "Marque", field: "marque", flex: 1 },
    { headerName: "Modele", field: "modele", flex: 1 },
    {
      headerName: "Ministère",
      field: "ministere",
      flex: 1
    },
    { headerName: "Options", field: "options", renderCell: options },
  ]);

  return (
    <div className="mutation page">
      <Helmet>
        <title>Mutation</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Mutation de propriété</h2>
      <div className="array">
          <Box sx={{ height: 579, width: "100%" }}>
            <DataGridPremium
              sx={{ borderRadius: 0 }}
              density="compact"
              components={{ Toolbar: GridToolbar}}
              rows={immaData}
              columns={columnDefs}
              autoPageSize
              pagination
              disableSelectionOnClick
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              />
          </Box>
        </div>
    </div>
  );
};

export default Mutation;
