import { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import Erreurs from "../erreurs/Erreurs";
const StepOne = ({ handleNextStep ,immatriculation,setImmatriculation,erreurs,setErreurs,reservationListe}) => {

  const [typeImm,setTypeImm] = useState(immatriculation)
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
    defaultValues:{
        immatriculation
    }
});
  useEffect(() => {
    reset(immatriculation);

  },[immatriculation]) 

  // const handleChange = (e)=>{
  //    setImmatriculation({...immatriculation,modeImmatriculation:e.target.value});
  //    if( e.target.value.length > 0 ){
  //      setStatus(true);
  //    }else {
  //     setStatus(true);
  //    }
  // }
  const handleInput = (e) => {
    if(erreurs) setErreurs('');
    if(e.target.name === "paiementReference"){
      if(e.target.value.length <= 22)
        setImmatriculation({...immatriculation,[e.target.name]:e.target.value.toUpperCase()});
    }else if(e.target.name === 'reservation_id'){
      setImmatriculation({...immatriculation,[e.target.name]:e.target.value});
    }
    // else if(e.target.name === "typeOrganisme")  
    //   setImmatriculation({...immatriculation,[e.target.name]:e.target.value,modeImmatriculation:'',paiementReference:''});
    // else  setImmatriculation({...immatriculation,[e.target.name]:e.target.value});  
  }
  return (
    
    <div className="step-one">
      <form onSubmit={handleSubmit(handleNextStep)}>
       <div className="error-msg">{erreurs}</div>
        <div className="input-group">
          {/* <div>
            <label>Le type d'organisme
              <select name="typeOrganisme" value={immatriculation.typeOrganisme}
                {...register('typeOrganisme', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                   validate: (value) => value != 0 
                  },
                )}
               >
                  <option value={0}>Selectionnez le type d'organisme</option>
                  <option value={"Publique"}>Publique</option>
                  <option value={"Privé"}>Privé</option>
              </select>
            </label>
            {errors.typeOrganisme && (
                      <span className="error-msg">Veuillez selectionner le type d'organisme</span>
               )}
          </div> */}
     
   
         
            <label>Référence de paiement
               <input type="text" name="paiementReference" value={immatriculation.paiementReference}
                  placeholder="Numéro de référence du paiement." 
                  {...register('paiementReference', {
                      onChange: (e) => {
                          handleInput(e)
                      },
                  required:true,maxLength:22,minLength:10},
                  )}
               />
            {errors.paiementReference && errors.paiementReference?.type === "required" && (
                <span className="error-msg">Le Numéro de Référence de paiement est obligatoire.</span>
             )}
             {errors.paiementReference && errors.paiementReference?.type === "minLength" && (
                 <span className="error-msg">Le caractère minimum est dix (10).</span>
              )}
              {errors.paiementReference && errors.paiementReference?.type === "maxLength" && (
                  <span className="error-msg">Le caractère maximum est vingt deux(22).</span>
              )}
            </label>
           <label>Reservation
                <select name="reservation_id" onChange={handleInput} value={immatriculation.reservation_id}>
                    <option value="">Selectionnez la reservation</option>
                    {reservationListe?.length > 0 && reservationListe.map((reservationListe) =>
                     (<option value={reservationListe.reservation_id} key={reservationListe.reservation_id}>{reservationListe.nomReservation}</option>))}
                </select>
           </label>
        </div>
        
        
        <button type="submit">Suivant</button>
      </form>
    </div>
  );
};

export default StepOne;
