export const colors = [
  {
    id: 1,
    name: "Blanc",
    hex: "#fff",
  },
  {
    id: 2,
    name: "Noir",
    hex: "#000",
  },
  {
    id: 3,
    name: "Gris",
    hex: "#808080",
  },
  {
    id: 4,
    name: "Argent",
    hex: "#C0C0C0",
  },
  {
    id: 5,
    name: "Rouge",
    hex: "#FF0000",
  },
  {
    id: 6,
    name: "Bleu",
    hex: "#0068FF",
  },
  {
    id: 7,
    name: "Marron",
    hex: "#964B00",
  },
  {
    id: 8,
    name: "Vert",
    hex: "#00FF00",
  },
  {
    id: 9,
    name: "Beige",
    hex: "#F5F5DC",
  },
  {
    id: 10,
    name: "Jaune",
    hex: "#FFD700",
  },
  {
    id: 11,
    name: "Orange",
    hex: "#FFA500",
  },
  {
    id: 12,
    name: "Violet",
    hex: "#800080 ",
  },
  {
    id: 13,
    name: "Rose",
    hex: "#ffc0cb ",
  },
  {
    id: 14,
    name: "Autre",
    hex: "#FFF ",
  },
];
