import {useEffect,useState,useMemo} from 'react';
import { BiUpload } from 'react-icons/bi'
import { AiOutlineFile } from 'react-icons/ai'
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs'

const StepFour = ({ handleNextStep, handlePrevStep,immatriculation,setImmatriculation,stepChk,setStepChk }) => {
  const [previews,setPreviews] = useState({previews1:'',previews2:'',previews3:'',previews4:''});
  const isstepValid = useMemo(() => {
    return immatriculation.image1 != '' && immatriculation.image2 != ''
           && immatriculation.image3 != '' && immatriculation.image4 != ''
  },[immatriculation]);
  useEffect(() => {
     setPreviews({...previews,
      previews1: immatriculation.image1 !='' && immatriculation.image1 ? URL.createObjectURL(immatriculation.image1):'',
      previews2: immatriculation.image2 != '' && immatriculation.image2 ? URL.createObjectURL(immatriculation.image2):'',
      previews3: immatriculation.image3 != '' && immatriculation.image3 ? URL.createObjectURL(immatriculation.image3):'',
      previews4: immatriculation.image4 !='' && immatriculation.image4 ? URL.createObjectURL(immatriculation.image4):'',
    });
   setStepChk({...stepChk,step4:isstepValid});
  },[immatriculation]);
  const onFileChange = (event) => {
    //console.log(event.target.files[0])
    if(event.target.files[0])
      setImmatriculation({...immatriculation,[event.target.name]:event.target.files[0]});
   
  }
  //console.log(previews.previews1);
  return (
    <div className="step-four step-files">
      <div className="files">
        <div className="file-wrapper">
          <p>Vue de face</p>
          <div className="image">
            <img src={previews.previews1?previews.previews1:""} alt="Photo de face" style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button> 
             <input type="file" name="image1" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

        <div className="file-wrapper">
          <p>Vue de dos</p>
          <div className="image">
            <img src={previews.previews2} alt=""  style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button>
            <input type="file" name="image2" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

        <div className="file-wrapper">
          <p>Vue de profil</p>
          <div className="image">
            <img src={previews.previews3} alt="" style={{height:'100%'}}/>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer une photo</button>
            <input type="file" name="image3" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>

        <div className="file-wrapper">
          <p>Fonds de dossier</p>
          <div className='image'>
            {previews.previews4 === '' ? <AiOutlineFile size={50} /> : <BsFillFileEarmarkCheckFill size={50} />}
          </div>
          {/* <div className="image">
            <img src={previews.previews4} alt="" style={{height:'100%'}}/>
          </div> */}
          <div className="upload-btn-wrapper">
            <button className="btn"> <BiUpload /> Importer le dossier</button>
            <input type="file" name="image4" onChange={onFileChange} accept="image/*"/>
          </div>
        </div>
      </div>
      
      <div className="buttons">
        <button className="secondary" onClick={handlePrevStep}>
          Precedent
        </button>
        <button onClick={handleNextStep} disabled={isstepValid?false:true}>Suivant</button>
      </div>
    </div>
  );
};

export default StepFour;
