import { useState, useEffect,useContext } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { getGenreByID, getTypeByID } from "../../services/vehicule.service";
import { getMinistereById } from "../../services/organisation.service";
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import { getPaysByID } from "../../utils/helper/functions";
import {
  nouvelleImmatriculation,
  InitialiseImmatriculation,
} from "../../services/immatriculation.service";
import Erreurs from "../erreurs/Erreurs";
import Viewer from "react-viewer";
import Spinner from "../Spinner/Spinner";
import { ComboContext } from "../../services/Context/Contexts";

const StepFive = ({
  handlePrevStep,
  immatriculation,
  setStep,
}) => {
  const [isGenerated, setIsGenerated] = useState(false);
  const [minister, setMinister] = useState("");
  const [genre, setGenre] = useState("");
  const [type, setType] = useState("");
  const [pays, setPays] = useState();
  const [erreurs, setErreurs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[modele,setModele] = useState();
  const[marque,setMarque] = useState();
  const {modeles,marques,genres,typeVehicules,ministeres} = useContext(ComboContext);
  //Image viewer
  const [isVisible, setIsVisible] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const images = [
    { src: immatriculation?.image1 ? URL.createObjectURL(immatriculation.image1):'' },
    { src: immatriculation?.image2 ? URL.createObjectURL(immatriculation.image2):'' },
    { src: immatriculation?.image3 ?URL.createObjectURL(immatriculation.image3) :''},
    { src: immatriculation?.image4? URL.createObjectURL(immatriculation.image4):'' },
  ];

  const handleImageClick = (index) => {
    setImageIndex(index);
    setIsVisible(true);
  };

  useEffect(() => {
    setPays(getPaysByID(immatriculation.idpays));
    if (Array.isArray(modeles) && modeles.length != 0) setModele(modeles.filter(m=>m.id == immatriculation.model)[0].title);
    if (Array.isArray(marques) && marques.length != 0) setMarque(marques.filter(m=>m.id == immatriculation.marque)[0].title);
    if (Array.isArray(genres) && genres.length != 0) setGenre(genres.filter(m=>m.genre_id == immatriculation.genre)[0]?.nom);
    if (Array.isArray(typeVehicules) && typeVehicules.length != 0) setType(typeVehicules.filter(m=>m.type_id == immatriculation.type)[0]?.nom);
    if (Array.isArray(ministeres) && typeVehicules.ministeres != 0) setMinister(ministeres.filter(m=>m.ministere_id == immatriculation.ministere)[0]?.nom);
  }, []);

  const openFile = (url) => {
    window.open(URL.createObjectURL(url));
  }
 
  const submitForm = async (e) => {
    e.preventDefault();
    setErreurs([]);
    setIsLoading(true);
    const data = await nouvelleImmatriculation(immatriculation);
    // console.log(data);   setIsLoading(false);return;
    if (data.success === false) {
      if(data?.status === 417){
        toast.error("Operation effectuée avec mais la synchronisation de la chaine a échoué.");
        InitialiseImmatriculation(immatriculation);
        setStep(1); return;
      }
      setErreurs(data.messages); 
      toast.error("Echec dans l'enregistrement!");
      setIsLoading(false);
    } else {
      if (immatriculation.ancienNumMat.length > 0) {
        toast.success("Reimmatriculation effectuée avec succès.");
        setIsLoading(false);
      }
      else toast.success("Immatriculation effectuée avec succès.");
      InitialiseImmatriculation(immatriculation);
      setStep(1);
      setIsLoading(false);
    }
  };
 //console.log(immatriculation)
  return (
    <div className="step-five step-submit">
      {isLoading && <Spinner />}
      <div className="summary">
        <div className="info-group">
          <div>
            <span>Numero de chassis</span>
            <strong>
              {immatriculation.numChassie != ""
                ? immatriculation.numChassie
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Affectation</span>
            <strong>{minister}</strong>
          </div>
          <div>
            <span>Mode d'acquisition</span>
            <strong>{immatriculation.acquisition}</strong>
          </div>
          <div>
            <span>Status</span>
            <strong>{isGenerated ? "Soumise" : "Non Soumise"}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Mode d'exploitation</span>
            <strong>{immatriculation.modeImmatriculation}</strong>
          </div>
          <div>
            <span>Nombre de portes</span>
            <strong>
              {immatriculation.nbPorte != ""
                ? immatriculation.nbPorte
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Places assises</span>
            <strong>
              {immatriculation.nbPlaceAssise != ""
                ? immatriculation.nbPlaceAssise
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Places debout</span>
            <strong>
              {immatriculation.nbPlaceDebout != ""
                ? immatriculation.nbPlaceDebout
                : "Non Identifiée"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Provenance</span>
            <strong>{pays}</strong>
          </div>
          <div>
            <span>Genre de véhicule</span>
            <strong>{genre}</strong>
          </div>
          <div>
            <span>Type</span>
            <strong>{type}</strong>
          </div>
          <div>
            <span>Marque</span>
            <strong>{marque?marque:'Non Fournie'}</strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Modèle</span>
            <strong>{modele?modele:'Non Fournie'}</strong>
          </div>
          <div>
            <span>Année</span>
            <strong>{immatriculation.annee}</strong>
          </div>
          <div>
            <span>Carosserie</span>
            <strong>
              {immatriculation.carrosserie != 0
                ? immatriculation.carrosserie
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Energie</span>
            <strong>
              {immatriculation.energie != ""
                ? immatriculation.energie
                : "Non Identifiée"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Couleur</span>
            <strong>{immatriculation.couleur}</strong>
          </div>
          <div>
            <span>Mode de transmission</span>
            <strong>{immatriculation.transmission}</strong>
          </div>
          <div>
            <span>Kilometrage</span>
            <strong>
              {immatriculation.kilometrage != ""
                ? immatriculation.kilometrage
                : "Non Identifié"}
            </strong>
          </div>
          <div>
            <span>Cylindre</span>
            <strong>
              {immatriculation.cylindre != ""
                ? immatriculation.cylindre
                : "Non Identifié"}
            </strong>
          </div>
        </div>

        <div className="info-group">
          <div>
            <span>Nombre d'essieux</span>
            <strong>{immatriculation.nbreEssuie}</strong>
          </div>

          <div>
            <span>Poids à vide</span>
            <strong>{immatriculation.pv}</strong>
          </div>
          <div>
            <span>Charge Utile</span>
            <strong>{immatriculation.cu}</strong>
          </div>
          <div>
            <span>Poids total Autorisé à Charge</span>
            <strong>
              {parseInt(immatriculation.pv) + parseInt(immatriculation.cu)}
            </strong>
          </div>
        </div>
        <div className="info-group">
          <div>
            <span>Premiere mise en cirulation</span>
            <strong>
              {immatriculation.dateP != ""
                ? moment(immatriculation.dateP).format("DD/MM/YYYY")
                : "Non Identifiée"}
            </strong>
          </div>
          <div>
            <span>Ancien numero d'immatriculation</span>
            <strong>
              {immatriculation.ancienNumMat != ""
                ? immatriculation.ancienNumMat
                : "Non renseigné"}
            </strong>
          </div>
          <div></div>
          <div></div>
        </div>
        <div className="info-group">
          <div>
            <span>Vue de face</span>
            <div className="image" onClick={() => handleImageClick(0)}>
              <img src={immatriculation.image1?URL.createObjectURL(immatriculation.image1):''} alt="" />
            </div>
          </div>
          <div>
            <span>Vue de dos</span>
            <div className="image" onClick={() => handleImageClick(1)}>
              <img src={immatriculation.image2 ? URL.createObjectURL(immatriculation.image2):''} alt="" />
            </div>
          </div>
          <div>
            <span>Vue de profil</span>
            <div className="image" onClick={() => handleImageClick(2)}>
              <img src={immatriculation.image3 ? URL.createObjectURL(immatriculation.image3):''} alt="" />
            </div>
          </div>
          {/* <div>
            <span>Fonds de dossier</span>
            <div className="image" onClick={() => handleImageClick(3)}>
              <img src={immatriculation.image4 ? URL.createObjectURL(immatriculation.image4):''} alt="" />
            </div>
          </div> */}
          <div className="fond-dossier" onClick={() => openFile(immatriculation.image4)}>
           <span>Fond de Dossiers</span>
            <div className="image">
               <BsFileEarmarkArrowDownFill size={50}/>
             </div>
           </div> 
        </div>
      </div>
      <Erreurs validation={erreurs} />
      <div className="image-viewer">
        <Viewer
          visible={isVisible}
          onClose={() => {
            setIsVisible(false);
          }}
          images={images}
          activeIndex={imageIndex}
        />
      </div>
      <div className="buttons">
        <button className="secondary" onClick={handlePrevStep}>
          Precedent
        </button>
        {/* {!isGenerated && (
          <button className="primary" onClick={submitForm}>
            Générer un numéro d'immatriculation
          </button>
        )} */}
        <button className="primary" onClick={submitForm}>
          {" "}
          Soumettre{" "}
        </button>
      </div>
    </div>
  );
};

export default StepFive;
